import '~/scripts/integrations/jquery-extended';
import FormErrors from '~/scripts/lib/FormErrors';

export default class {
  constructor() {
    this.isFirstTimeShowingPassword = true;
    this.$passwordWrapper = $("#password-wrapper");
    this.$formsSections = $(".home-form");
    this.$forms = this.$formsSections.find("form");
    this.addEvents();
    this.setupPasswordUtils();

    $(".scrollToItem").on(R.touchEvent, function(e) {
      var $this = $(this);
      var target = $this.attr("href");
      e.preventDefault();
      $("html, body").animate({
        scrollTop: $(target).offset().top - 200
      });
    });

    this.goToAnchorIfItIsThere();
  }

  goToAnchorIfItIsThere() {
    var hash = window.location.hash, offset;

    if (!hash.length) {
      return;
    }

    offset = $(hash).offset().top;

    $("html, body").animate({
      scrollTop: (offset - 30) + "px"
    });
  }

  setupPasswordUtils() {
    var pu = new R.ui.PasswordUtils();
    pu.bindInputWithPasswordMeter();

    if (this.$passwordWrapper.hasClass("current")) {
      pu.bindTogglePasswordVisibility(this.$passwordWrapper);
    }
  }

  targetElementOffset() {
    var cache = this.targetElementOffset.cache || 0;
    var $action = $("#action h3");

    if (cache === 0) {
      if ($action.length > 0) {
        cache = $("#action h3").offset().top - 100;
      } else {
        cache = 1;
      }
    }

    return (this.targetElementOffset.cache = cache);
  }

  addEvents() {
    var that = this;

    $body.bind("view:change", function(e, to) {
      var key = {
        '#password-wrapper': 'sign-up-name-submitted',
        '#phone-wrapper': 'sign-up-email-submitted',
      };
      if (window.analytics && key[to]) {
        window.analytics.track(key[to]);
      }

      if (to === "#password-wrapper" && that.isFirstTimeShowingPassword) {
        that.setupPasswordUtils();
        that.isFirstTimeShowingPassword = false;
      }
    });

    $("#new_user").submit(function(e) {
      e.preventDefault();
    });

    $(window).bind("ajaxify:success", function(e, SAFEvent) {
      var isPersonalAccount = SAFEvent.data.company.domain === "users";
      that.setNextForm(SAFEvent);
      that.next($("#" + SAFEvent.event.target.id).closest(".home-form"), isPersonalAccount);
    });

    $(window).bind("ajaxify:error", "#user_password_form", this.handleError.bind(this));
    $(window).bind("ajaxify:error", "#full_name_form", this.handleError.bind(this));

    this.$forms.submit(function(e) {
      e.preventDefault();
    });
  }

  handleError(e, target_id, errors) {
    var formId = target_id.target.id;
    var errorMessage = (errors.responseJSON.errors || [])[0];
    var formErrors;
    if (errorMessage) {
      formErrors = new FormErrors($('#' + formId + ' .form-wrapper'), { "base": [errorMessage] });
      formErrors.renderErrors();
    }
  }

  removeEvents() {
    $("#new_user").unbind();

    $body.unbind("view:change");

    $window.off("ajaxify:success");
    $window.off("ajaxify:error", "#user_password_form");
    $window.off("ajaxify:error", "#full_name_form");
  };

  next(current, isPersonalAccount) {
    var id = current.attr("id");

    this.reset();

    if (id === "banner" ) {
      R.transition.slide("#banner", "#phone-wrapper");
      setTimeout(function() {
        $("#user_first_name").focus();
      }, 1000);

    } else {
      R.transition.slide("#phone-wrapper", "#password-wrapper");
      setTimeout(function() {
        $("#user_password").focus();
      }, 1000);
    }
  }

  reset() {
    $("html, body").animate({
      scrollTop: this.targetElementOffset()
    });

    $("input").blur();

    $window.focus();
  }

  setNextForm(SAFEvent) {
    $(".hidden-email").val(SAFEvent.data.user.email);
    $(".hidden-token").val(SAFEvent.data.user.signup_session_token);
    if (SAFEvent.event.target.id === "new_user") {
      $("#full_name_attributes_name").val(SAFEvent.data.company.name);
    }
  }
}
